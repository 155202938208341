export const shipmentEnum = {
  HAND_IN: 'HAND_IN',
  T_CAT: 'T_CAT',
  SELF_PICK_UP: 'SELF_PICK_UP',
}

export const shipmentMsgEnum = {
  HAND_IN: '親送',
  T_CAT: '黑貓',
  SELF_PICK_UP: '自取',
}

export const shipmentValueEnum = {
  HAND_IN: 1,
  T_CAT: 2,
  SELF_PICK_UP: 3,
}


export const shipmentValueToKeyEnum = {
  '1': 'HAND_IN',
  '2': 'T_CAT',
  '3': 'SELF_PICK_UP',
}

export const temperatureEnum = {
  ROOM_TEMPERATURE: 'ROOM_TEMPERATURE',
  LOW_TEMPERATURE: 'LOW_TEMPERATURE',
}

export const temperatureMsgEnum = {
  ROOM_TEMPERATURE: '常溫',
  LOW_TEMPERATURE: '低溫',
}

export const temperatureValueEnum = {
  ROOM_TEMPERATURE: 1,
  LOW_TEMPERATURE: 2,
}

export const temperatureValueToKeyEnum = {
  '1': 'ROOM_TEMPERATURE',
  '2': 'LOW_TEMPERATURE',
}

export const volumeEnum = {
  SIXTY_CM: 'SIXTY_CM',
  NINETY_CM: 'NINETY_CM',
  HUNDRED_TWENTY_CM: 'HUNDRED_TWENTY_CM',
}

export const volumeMsgEnum = {
  SIXTY_CM: '60公分',
  NINETY_CM: '90公分',
  HUNDRED_TWENTY_CM: '120公分',
}

export const volumeValueEnum = {
  SIXTY_CM: 1,
  NINETY_CM: 2,
  HUNDRED_TWENTY_CM: 3,
}

export const volumeValueToKeyEnum = {
  '1': 'SIXTY_CM',
  '2': 'NINETY_CM',
  '3': 'HUNDRED_TWENTY_CM',
}

export const createDefaultFreightState = () => {
  const result = {}
  for (let k in shipmentEnum) {
    if (k === shipmentEnum.SELF_PICK_UP) continue

    result[k] = {}
    for (let k2 in temperatureEnum) {
      result[k][k2] = {}
      for (let k3 in volumeEnum) {
        result[k][k2][k3] = 0
      }
    }
  }
  return result
}

export const translateCustomerFreightState = (freights = []) => {
  const result = createDefaultFreightState()
  freights.forEach(e => {
    const {shipment, temperature, volume, price} = e
    const shipmentObj = result[shipment]

    if (shipment === shipmentEnum.SELF_PICK_UP) return

    if (shipmentObj != null && shipmentObj[temperature]) {
      const temperatureObj = shipmentObj[temperature]
      if (temperatureObj != null)
        temperatureObj[volume] = price
    }
  })
  return result
}
