<template>
<div class="freight-table">
  <div class="freight-table__header">
    <div class="freight-table__header__item freight-table__header__item-expend" @click="handleExpend">
      <a-icon type="minus-square" v-if="expend" />
      <a-icon type="plus-square" v-else />
    </div>
    <div class="freight-table__header__item freight-table__header__item-shipment">{{shipmentMsgEnum[shipment]}}</div>
    <div class="freight-table__header__item">溫層</div>
    <div class="freight-table__header__item">材積</div>
    <div class="freight-table__header__item freight-table__header__item-price">運費</div>
  </div>
  <div class="freight-table__body">
    <div class="freight-table__body__row" v-for="(e, i) in currentFreights" :key="e.key">
      <div class="fb-td fb-td-expend"></div>
      <div class="fb-td fb-td-shipment"></div>
      <div class="fb-td fb-td-temperature">{{temperatureMsgEnum[e.temperature]}}</div>
      <div class="fb-td">{{volumeMsgEnum[e.volume]}}</div>
      <div class="fb-td fb-td-price">
        <template v-if="i !== editState.index">
          <span>{{e.price == null ? '未設定' : e.price}}</span>
          <a-icon class="fb-td-price__icon" type="edit" @click="handleEdit(i, e.price)" />
        </template>
        <a-input style="width: calc(100% - 24px);" v-model="editState.price" placeholder="請輸入運費(Enter修改, 負數為0)" :min="0" type="number" @pressEnter="handleEnterEdit" autoFocus v-else />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  shipmentEnum,
  shipmentMsgEnum,
  temperatureEnum,
  temperatureMsgEnum,
  volumeEnum, volumeMsgEnum
} from '../../../utils/freight-helper'

export default {
  name: 'freight-table',
  props: {
    shipment: {
      type: String,
      default: shipmentEnum.HAND_IN,
    }
  },
  data(){
    return {
      freights: [],
      expend: false,
      editState: {
        index: undefined,
        price: undefined,
      },
      freightsObj: {
        [`${shipmentEnum.HAND_IN}__${temperatureEnum.ROOM_TEMPERATURE}__${volumeEnum.SIXTY_CM}`]: 110,
        [`${shipmentEnum.HAND_IN}__${temperatureEnum.ROOM_TEMPERATURE}__${volumeEnum.NINETY_CM}`]: 150,
        [`${shipmentEnum.HAND_IN}__${temperatureEnum.ROOM_TEMPERATURE}__${volumeEnum.HUNDRED_TWENTY_CM}`]: 200,
        [`${shipmentEnum.HAND_IN}__${temperatureEnum.LOW_TEMPERATURE}__${volumeEnum.SIXTY_CM}`]: 130,
        [`${shipmentEnum.HAND_IN}__${temperatureEnum.LOW_TEMPERATURE}__${volumeEnum.NINETY_CM}`]: 200,
        [`${shipmentEnum.HAND_IN}__${temperatureEnum.LOW_TEMPERATURE}__${volumeEnum.HUNDRED_TWENTY_CM}`]: 250,
        [`${shipmentEnum.T_CAT}__${temperatureEnum.ROOM_TEMPERATURE}__${volumeEnum.SIXTY_CM}`]: 130,
        [`${shipmentEnum.T_CAT}__${temperatureEnum.ROOM_TEMPERATURE}__${volumeEnum.NINETY_CM}`]: 170,
        [`${shipmentEnum.T_CAT}__${temperatureEnum.ROOM_TEMPERATURE}__${volumeEnum.HUNDRED_TWENTY_CM}`]: 210,
        [`${shipmentEnum.T_CAT}__${temperatureEnum.LOW_TEMPERATURE}__${volumeEnum.SIXTY_CM}`]: 160,
        [`${shipmentEnum.T_CAT}__${temperatureEnum.LOW_TEMPERATURE}__${volumeEnum.NINETY_CM}`]: 225,
        [`${shipmentEnum.T_CAT}__${temperatureEnum.LOW_TEMPERATURE}__${volumeEnum.HUNDRED_TWENTY_CM}`]: 290,
      }
    }
  },
  computed: {
    shipmentMsgEnum() { return shipmentMsgEnum },
    temperatureMsgEnum() { return temperatureMsgEnum },
    volumeMsgEnum() { return volumeMsgEnum },
    currentFreights() {
      if (this.expend) return this.freights
      return []
    },
  },
  methods: {
    init(isAdd = true, propFreightsObj = {}) {
      const {freightsObj, shipment: selfShipment} = this
      const newFreights = []

      this.expend = false
      this.editState.index = undefined
      this.freights = []

      if (isAdd) {
        for (const k in freightsObj) {
          const [shipment, temperature, volume] = k.split('__')
          const price = freightsObj[k]

          if (shipment !== selfShipment) continue

          newFreights.push({
            key: k,
            temperature,
            volume,
            price,
          })
        }
        this.freights = newFreights
        return
      }

      for (const k in freightsObj) {
        const [shipment, temperature, volume] = k.split('__')

        if (shipment !== selfShipment) continue

        const propFreight = propFreightsObj[k]
        newFreights.push({
          key: k,
          temperature: propFreight?.temperature ?? temperature,
          volume: propFreight?.volume ?? volume,
          price: propFreight?.price,
        })
      }
      this.freights = newFreights
    },
    handleExpend() {
      this.expend = !this.expend
      this.editState.index = undefined
    },
    handleEdit(i, price) {
      this.editState.index = i
      this.editState.price = price
    },
    handleEnterEdit() {
      let priceNum = Number(this.editState.price)
      if (isNaN(priceNum) || priceNum < 0) priceNum = 0
      this.freights[this.editState.index].price = priceNum
      this.editState.index = undefined
    }
  }
}
</script>

<style scoped lang="scss">
.freight-table {
  &__header {
    display: flex;
    background: #fafafa;
    color: rgba(0, 0, 0, 0.85);
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    &__item {
      border-right: 1px solid #e8e8e8;
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      &-expend {
        width: 48px;
        flex: initial;
        cursor: pointer;
      }
      &-shipment {
        width: 60px;
        flex: initial;
      }
      &-price {
        width: 212px;
        flex: initial;
      }
    }
  }
  &__body {
    &__row {
      display: flex;
      color: rgba(0, 0, 0, 0.85);
      &:last-child .fb-td {
        //border-bottom: 0;
      }
      .fb-td {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        min-height: 48px;
        border-right: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        &-expend {
          border-right: 0;
          border-bottom: 0;
          border-top: 0;
          width: 48px;
          flex: initial;
        }
        &-shipment {
          border-bottom: 0;
          border-top: 0;
          border-right: 0;
          width: 60px;
          flex: initial;
        }
        &-temperature {
          border-left: 1px solid #e8e8e8;
        }
        &-price {
          width: 212px;
          flex: initial;
          &__icon {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
